import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../Assets/black-logo-remove.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useStyles } from "./styles";

function ResponsiveAppBar() {
  const classes = useStyles();
  const [scroll, setScroll] = useState(false);
  const history = useLocation();
  const pathname = history?.pathname;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setScroll(window?.scrollY > 0);
  };

  return (
    <>
      {pathname === "/" ? (
        <header className={`${classes.header} ${scroll ? classes.sticky : ""}`}>
          <img src={Logo} height="130" alt="header logo" loading="lazy" />
          {/* <MenuIcon
            onClick={() => console.log('hello')}
            fontSize="large"
            sx={{ color: "#ffffff", cursor: "pointer" }}
          /> */}
        </header>
      ) : (
        <header className={`${classes.header} ${classes.newHeader}`}>
          <img src={Logo} height="130" alt="new header logo" loading="lazy" />
        </header>
      )}
    </>
  );
}
export default ResponsiveAppBar;
