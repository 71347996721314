import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Logo from "../../Assets/black-logo-remove.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        borderTopColor: "rgba(255, 255, 255, 0.12)",
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)),#17181A",
      }}
    >
      <div className={classes.footer}>
        <div className={classes.address}>
          <span className={classes.socialIcons}>
            <Link
              style={{ display: "flex" }}
              to="https://wa.me/+966568381213"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon fontSize="large" style={{ color: "#fff" }} />
            </Link>
          </span>

          <span className={classes.socialIcons}>
            <Link
              style={{ display: "flex" }}
              to="mailto: info@triochem-petro.com"
              rel="noopener noreferrer"
            >
              <EmailIcon fontSize="large" style={{ color: "#fff" }} />
            </Link>
          </span>
        </div>
        <div>
          <div>
            <img src={Logo} alt="footer logo" height="60" />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Footer;
