import { makeStyles } from "@mui/styles";
import Theme from "../../theme";

export const useStyles = makeStyles((theme) => ({
  services: {
    width: '100%',
    paddingLeft: '4%',
    paddingRight: '4%',
  },
  servicesContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    borderBottom: `1px solid rgb(212 209 209)`,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    marginTop: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      marginTop: '0px'
    },
  },
  descriptionWrapper: {
    width: '50%',
    textAlign: 'left',
    padding: 20,
    lineHeight: '28px',
    borderRadius: '5px',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
    // boxShadow: '0 0.125rem 0.375rem rgba(0,0,0,.05), 0 0.5rem 1.2rem rgba(0,0,0,.1) !important',
  },
  description: {
    width: '50%',
    color: Theme.palette.info.dark,
    textAlign: 'justify',
    paddingTop: '10px',
  },
  image: {
    width: '40%',
    // boxShadow: '0 0.125rem 0.375rem rgba(0,0,0,.05), 0 0.5rem 1.2rem rgba(0,0,0,.1) !important',
    borderRadius: '5px',
    padding: '0 20px',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  },
  subTitle: {
    fontWeight: 'bold'
  }
}));
