import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const Main = lazy(() => import('./Components/Main/Main'));
const ProductDetails = lazy(() => import('./Components/ProductDetails/ProductDetails'));
const Header = lazy(() => import('./Components/Header/Header'));
const Footer = lazy(() => import('./Components/Footer/Footer'));

function AppRoute() {
  return (
    <Router>
      <Header />
        <Suspense fallback={"Loading...."}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/product-details" element={<ProductDetails />} />
          </Routes>
        </Suspense>
        <Footer />
    </Router>
  );
}

export default AppRoute;
