import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    footer: {
        paddingLeft: '4%',
        paddingRight: '4%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    address: {
        width: '50%',
        display: 'flex'
    },
    socialIcons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.12)',
        padding: 15,
        borderRadius: '50%',
        margin: '0 10px',
        cursor: 'pointer',
        '& svg': {
            fontSize: '24px'
        }
    },
    mapouter: {
        width: '50%',
        position:'relative',
        textAlign:'right',
        // width: '600px',
        height: '400px'
    },
    gmap_canvas: {
        overflow: 'hidden',
        background: 'none!important',
        width: '600px',
        height: '400px'
    },
    gmap_iframe: {
        width: '600px!important',
        height: '400px!important'
    }
//   }
}))