import { makeStyles } from "@mui/styles";
import background from "../../Assets/background.webp";

export const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    //    backgroundImage: `url(${background})`,
    backgroundPosition: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    padding: "12px 24px",
    color: "#ffffff",
    backgroundImage: `linear-gradient(270deg,transparent 8%,rgba(0,0,0,.8)), url(${background})`,

    //    alignItems: 'center',
    //    backgroundImage:
    //    `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    //    url(${background})`,
    backgroundImage: `linear-gradient(270deg,transparent 8%,rgba(0,0,0,.8)), url(${background})`,
  }
}))