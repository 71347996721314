import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    width: '100%',
    textAlign: "center", 
    fontSize: '2.2rem', 
    fontWeight: 'bold',
    color: '#484242',
    margin: theme.palette.p
  },
}));
