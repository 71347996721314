import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { services } from "../../constants/servicesConstants";
import Title from "../Title/Title";
import SubTitle from "../SubTitle/SubTitle";

function Services() {
  const classes = useStyles();

  return (
    <Box id="our-services" component="div" className={classes.services}>
      <Title title="Our Services" />
      <Box component="div">
        {services?.map((service) => (
            <div key={service?.id} className={classes.servicesContent}>
              <div className={classes.descriptionWrapper}>
                <SubTitle
                  SubTitle={service?.name}
                  customClass={classes.subTitle}
                />
                <Typography variant="p" className={classes.description}>
                  {service?.description}
                </Typography>
              </div>

              <img
                src={service?.image}
                width="350"
                height="200"
                className={classes.image}
                loading="lazy"
                alt="service image"
              />
            </div>
        ))}
      </Box>
    </Box>
  );
}

export default Services;
