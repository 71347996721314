import {
  createTheme,
  responsiveFontSizes,
  lighten,
  darken,
} from "@mui/material/styles";

let Theme = createTheme({
  typography: {
    h3: {
      fontSize: '2rem',
      fontWeight: 700
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "none",
        },
      },
    },
  },
  palette: {
    common: {
      white: "#fff",
      black: "#000",
    },
    primary: {
      main: "#ffffff",
      light: lighten("#A70000", 0.47),
      dark: darken("#A70000", 0.5),
      contrastText: "#fff",
    },
    secondary: {
      light: lighten("#221F1F", 0.4),
      main: "#221F1F",
      dark: darken("#221F1F", 0.6),
      contrastText: "#e0e0e0",
    },
    info: {
      main: darken("#221F1F", 0.8),
      light: lighten("#221F1F", 0.47),
      dark: darken("#221F1F", 0.6),

    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#c7382e",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#F6F6F6",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
    success: {
      main: "#35712F",
      light: "#168171",
    },
    error: {
      main: "#D32F2F",
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4,0,0.2,1)",
      easeOut: "cubic-bezier(0.0,0,0.2,1)",
      easeIn: "cubic-bezier(0.4,0,1,1)",
      sharp: "cubic-bezier(0.4,0,0.6,1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 2560,
    },
  },
});

Theme = responsiveFontSizes(Theme);

export default Theme;
