import product1 from "../Assets/products-Alcohols.jpg";
import product2 from "../Assets/product-glycols.jpg";
import product3 from "../Assets/products-Amines.jpg";
import product4 from "../Assets/product-monomers.jpg";
import product5 from "../Assets/product-Refined-petroleum-products.jpg";
import product6 from "../Assets/product-aromatics.jpg";
import product7 from "../Assets/product-other-chemicals.jpg";
import product8 from "../Assets/product-polymers.jpg";

export const ourProducts = [
    { id: 1, name: "Alcohols", image: product1 },
    { id: 2, name: "Amines", image: product3 },
    { id: 3, name: "Aromatics", image: product6 },
    { id: 4, name: "Glycols", image: product2 },
    { id: 5, name: "Monomers", image: product4 },
    { id: 6, name: "Other Chemicals", image: product7 },
    { id: 7, name: "Polymers", image: product8 },
    { id: 8, name: "Refined Petroleum Products", image: product5 },
];