import { makeStyles } from "@mui/styles";
import background from "../../Assets/background.webp";

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // position: 'relative',
  },
  main: {
    paddingLeft: '4%',
    paddingRight: '4%',
    position: 'relative',
    //    backgroundImage: `url(${background})`,
    backgroundPosition: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    padding: "12px 24px",
    color: "#ffffff",
    //backgroundImage: `linear-gradient(360deg,transparent 0%,rgba(0,0,0,1)), url(${background})`,
    backgroundImage: `linear-gradient(360deg,transparent 0%,rgba(0,0,0,1)), linear-gradient(rgba(13, 14, 16, 0) 10.3%, rgb(13, 14, 16) 100.5%), url(${background})`,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'center'
    //    backgroundImage:
    //    `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    //    url(${background})`,
    // backgroundImage: `linear-gradient(270deg,transparent 8%,rgba(0,0,0,.8)), url(${background})`,
    //    backgroundImage: 'linear-gradient(270deg,transparent 8%,rgba(0,0,0,.5))'
  },
  menuIcon: {
    position: 'absolute',
    top: 26, 
    right: 35,
    cursor: 'pointer',
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      top: 15, 
      right: 16,
      '& svg': {
        fontSize: '26px'
      }
    },
  },
  whatsappIcon: {
    position: 'absolute',
    right: 45,
    bottom: 25,
    [theme.breakpoints.down("sm")]: {
      right: 35,
    },
  },
  needHelp: {
    position: 'absolute',
    right: 90,
    bottom: 32,
    fontWeight: 400,
    fontSize: '0.75rem',
    backgroundColor: '#ffffff',
    color: '#000',
    padding: '8px',
    borderRadius: '5px',
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },
  article: {
    color: "#fff",
    textTransform: "none",
    marginTop: 80,
    marginBottom: 0,
    fontSize: "44px",
    fontWeight: 300,
    // maxWidth: 'min-content',
    paddingLeft: '40px',
    // fontFamily: `Raleway, sans-serif`,
    textShadow: '2px 2px 5px #161717',
    letterSpacing: '0.8px',
    // fontFamily: 'Satoshi',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '10px',
    },
  },
  title1: {
    fontSize: '3rem',
    lineHeight: '1.6',
    letterSpacing: '.24px',
    color: '#fff',
    // fontFamily: `'Raleway', sans-serif`,
    textShadow: '2px 2px 5px #161717',
    fontWeight: 700,
    fontFamily: 'Raleway',
    [theme.breakpoints.down("sm")]: {
      fontSize: '2rem',
    },
},
title2: {
  fontSize: '2.4rem',
  lineHeight: '1.6',
  letterSpacing: '.24px',
  color: '#fff',
  fontWeight: 400,
  fontFamily: 'Lato',
  textShadow: '2px 2px 5px #161717',
  [theme.breakpoints.down("sm")]: {
    fontSize: '1.8rem',
  },
},
aboutUs: {
  paddingLeft: '4%',
  paddingRight: '4%',
  [theme.breakpoints.down("sm")]: {
    borderBottom: '1px solid #d5d4d4'
  },
},
info: {
  textAlign: 'left',
  lineHeight: '28px',
},
  ourProducts: {
    width: "100%",
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "12px 24px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      padding: "0px",
      borderBottom: '1px solid #d5d4d4'
    },
  },
  product: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      boxShadow: 'unset',
    },
  },
  items: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    cursor: "pointer",
    justifyContent: "center",
  },
  wrap: {
    padding: '50px 0px 0px 40px',
    [theme.breakpoints.down("sm")]: {
      padding: '10px 0px 0px 10px',
    },
  },
  button: {
    /* animation: sample1 .8s infinite ease-in-out alternate; */
    padding: '18px 20px',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: '2.5px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: '#fff',
    border: '2px solid  #e2e2e2',
    borderRadius: '5px',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    outline: 'none',
    position: 'relative',
    textAlign: 'center',
    marginTop: '20px',
    backgroundImage: 'linear-gradient(116deg,#000 3%,#202020 99%)',
    fontWeight: 'normal',
},
  item: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    backgroundColor: 'transparent'
  },
  productImage: {
    display: "block",
    borderRadius: "4px",
    objectFit: 'cover',
    marginTop: '8px',
    boxShadow: '0 0.125rem 0.375rem rgba(0,0,0,.05), 0 0.5rem 1.2rem rgba(0,0,0,.1) !important',
    "&:hover": {
      transform: 'scale(1.05)',
      transition: '0.6s',
    }
  },
  services: {
    width: '100%'
  },
  servicesContent: {
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    width: '60%'
  },
  image: {
    width: '40%'
  }
}));
