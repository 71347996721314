import trading from "../Assets/petro-chemical-trading.jpg";
import blending from "../Assets/blending.jpg";
import iso from "../Assets/iso-logo.jpg";

export const services = [
  {
    id: 1,
    name: '1. Chemical Trading: On-spec and Off-spec Petrochemicals',
    description: `Triochem actively trades crude oil and refined products – including naphtha, gasoline, gasoil, jet fuel and fuel oil. Triochem also trades in petrochemicals products; these include ethylene, propylene, benzene, and xylene and most of the intermediate petrochemicals. These quality products are traded widely through a regional and international network with market coverage extending throughout South East Asia, South Asia, the Middle East, Africa.`,
    image: trading,
  },
  {
    id: 2,
    name: "2. Petrochemical Blending and Refining",
    description: `The bulk of waste generated during a chemical process needs to be disposed of or treated properly. As exposing such waste into the environment may cause a huge damage to the environment. We provide necessary support and services to maintain the system and provide proper waste treatment using the latest technology with our associate organization.`,
    image: blending,
  },
  {
    id: 3,
    name: "3. ISO tanks",
    description: `Triochem has it own ISO tanks of about 900 designed for the transportation of food grade, hazardous and non-hazardous chemicals available  in a wide range of capacities and can also be used as statutory storage tanks with the following parameters:
    External Length: 20ft ; 6,058 mm 
    External Width: 8ft ; 2,438 mm  
    External Height: 8ft 6in ; 2,591 mm 
    MAWP: up to 4.0 bar
    Capacity: 21,000 - 26,000 ltr  
    Tare:3,540- 3,830 kg`,
    image: iso,
  },
];
