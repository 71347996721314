import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Title from "../Title/Title";
import { useStyles } from "./styles";

function ContactUs() {
  const classes = useStyles();
  return (
    <Box id="contact-us" component="div" className={classes.contactUsWrapper}>
      <Title title="Contact Us" customClass={classes.title} />
      <Box component="div" className={classes.contactUs}>
        <Box component="div" className={classes.information}>
          <Typography className={classes.contactInfo}>
            <Typography component="span">Email ID : </Typography>
            <Typography component="span">
              <a
                style={{ color: "#98d1d1" }}
                href="mailto: info@triochem-petro.com"
              >
                info@triochem-petro.com
              </a>
            </Typography>
          </Typography>
          <Typography className={classes.contactInfo}>
            <Typography component="span">Phone Number : </Typography>
            <Typography component="span">
              <a style={{ color: "#98d1d1" }} href="tel:+971 4 272 774">
                +971 4 272 774
              </a>
            </Typography>
            &nbsp; &nbsp; &nbsp;
            <Typography component="span">
              <a style={{ color: "#98d1d1" }} href="tel:+966-568381213">
                +966-568381213
              </a>
            </Typography>
          </Typography>
          <Typography className={classes.contactInfo}>
            <Typography component="span">Address : </Typography>
            <Typography component="span">
              TRIOCHEM PETROCHEMICAL TRADING L.L.C P.O Box:112749, 5th Floor,
              Office:502, Umm Hurrair Building, Al Karama, Dubai- UAE.
            </Typography>
          </Typography>
        </Box>
        <Box component="div" className={classes.mapouter}>
          <div className={classes.gmap_canvas}>
            <iframe
              loading="lazy"
              className={classes.gmap_iframe}
              width="600"
              height="400"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Umm hurair building, karama&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://connectionsgame.org/">Connections Game</a>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUs;
