import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    contactUsWrapper: {
        paddingLeft: '4%',
        paddingRight: '4%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#17181A',
        color: '#ffffff'
    },
    title: {
        color: '#ffffff',
    }, 
    contactInfo: {
        padding: 15,
        '& > span:first-child': {
            color: '#D9D6E5',
            fontWeight: 'bold'
        }
    },
    contactUs: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
    },
    information: {
        margin: '0px 15px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    mapouter: {
        width: '50%',
        padding: 30,
        margin: '0px 15px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    gmap_canvas: {
        overflow: 'hidden',
        background: 'none!important',
        width: '600px',
        height: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    // gmap_iframe: {
    //     width: '600px!important',
    //     height: '400px!important'
    // }
}))