import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    // backgroundColor: '#F4F6F6',
    position: 'relative',
    color: '#000'
  },
}))