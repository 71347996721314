import React from "react";
import { useStyles } from "./styles";
import { WhatsApp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Services from "../Services/Services";
import Title from "../Title/Title";
import SubTitle from "../SubTitle/SubTitle";
import ContactUs from "../ContactUs/ContactUs";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ourProducts } from "../../constants/ourProductConstants";

function Main() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const contents = [
    { id: 1, name: "About Us", href: "#about-us" },
    { id: 2, name: "Our Products", href: "#our-products" },
    { id: 3, name: "Our Services", href: "#our-services" },
    { id: 4, name: "Contact Us", href: "#contact-us" },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "100vh",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ paddingTop: "40px", zIndex: 99999 }}>
        <CloseIcon
          onClick={toggleDrawer(anchor, false)}
          sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
        />
        {contents?.map((contents) => (
          <ListItem key={contents?.id} disablePadding>
            <ListItemButton>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "40px",
                }}
                href={contents?.href}
              >
                <ListItemText primary={contents?.name} />
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className={classes.mainWrapper}>
      <Box component="div" className={classes.main}>
        <div className={classes.article}>
          <h3 className={classes.title1} id="title1">
            Hi, We are TrioChem PetroChemical Trading L.L.C
          </h3>
          <h2 className={classes.title2} id="title2">
            Nice to meet you
          </h2>
        </div>
        <div className={classes.wrap}>
          <a href="#about-us">
            <button className={classes.button}>Click To Know More</button>
          </a>
        </div>

        <Link
          to="https://wa.me/+966568381213"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.whatsappIcon}
        >
          <WhatsApp fontSize="large" sx={{ color: "#25D366" }} />
        </Link>
        <h1 className={classes.needHelp}>
          <span style={{ paddingRight: "5px" }}>Need Help?</span>
          <b>
            <span>Contact Us</span>
          </b>
        </h1>
        <div className={classes.menuIcon}>
          {/* {['right'].map((anchor) => ( */}
          <React.Fragment key={"right"}>
            <MenuIcon
              onClick={toggleDrawer("right", true)}
              fontSize="large"
              className={classes.menuIcon}
            />
            {/* <Button onClick={toggleDrawer('right', true)}>{'Right'}</Button> */}
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
          </React.Fragment>
          {/* ))} */}
        </div>
      </Box>
      <Box id="about-us" component="div" className={classes.aboutUs}>
        <Title title="About Us" />
        <Box component="div" className={classes.content}>
          <Typography variant="p" component="p" className={classes.info}>
            Triochem petrochemicals, a trading company dedicated to sustainable
            growth focused on the global physical trade and distribution of
            polymers, olefins, and aromatic petrochemical products. Triochem's
            comprehensive understanding of evolving dynamics within the global
            petrochemicals market allows us to effectively manage risk and
            optimize value in the commercial transactions we undertake. Our
            competitive advantage is that our management processes are designed
            to bring value throughout the petrochemicals supply chain. We do
            this by working with our producer partners to optimize their plant
            capabilities while offering our customers the opportunity to buy
            from a balanced raw materials product slate at competitive terms
            with optimum availability and efficiency.
          </Typography>
          &nbsp;
          <Typography variant="p" component="p" className={classes.info}>
            Our commercial hubs in Saudi, India, Dubai, ideally position us in
            each of the world’s main petrochemical trading regions. This not
            only delivers benefits through the application of specialized local
            knowledge within each region, but also provides us broader coverage
            and market efficiency in the global trade of polymers, olefins, and
            aromatic petrochemical products It is our mission at Triochem to
            combine strategic trading with service excellence that will enable
            us to grow with our customers.
          </Typography>
        </Box>
      </Box>
      <Box id="our-products" component="div" className={classes.ourProducts}>
        <div className={classes.product}>
          <Title title="Our Products" />
          <Box component="div" className={classes.items}>
            {ourProducts?.map((product) => (
              <Box key={product?.id} component="div" className={classes.item}>
                <SubTitle SubTitle={product.name} />
                <Link to={`/product-details?productId=${product.id}`}>
                  <img
                    src={product?.image}
                    width="320"
                    height="160"
                    className={classes.productImage}
                    loading="lazy"
                    alt="product image"
                  />{" "}
                </Link>
              </Box>
            ))}
          </Box>
        </div>
      </Box>
      <Services />
      <ContactUs />
    </div>
  );
}

export default Main;
