import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    textAlign: "center", 
    fontSize: '2.2rem', 
    fontWeight: 700,
    padding: 20
  },
}));
