import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    transition: "0.6s",
    zIndex: 2,
    padding: "0px 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
    '& img': {
      [theme.breakpoints.down("sm")]: {
        height: '100px'
      },
    }
  },
  sticky: {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  newHeader: {
    backgroundColor: "#000",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    lineHeight: "1.14",
    letterSpacing: "0.16px",
    borderRadius: "100px",
    textTransform: "none",
    padding: "16px 20px",
    boxShadow: "0 5px 15px -2px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#ffffff",
  },
}));
