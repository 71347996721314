
import { useStyles } from './styles';
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import AppRoute from './AppRoute';
import Footer from './Components/Footer/Footer';
import Banner from './Components/Banner/Banner';

function App() {
  const classes  = useStyles();
  
  return (
    <div className={classes.layout}>
      <AppRoute />
    </div>
  );
}

export default App;
