import { ClassNames } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

function Title({ title, customClass }) {
  const classes = useStyles();
  return (
    <Typography classes={{ root: `${classes.title} ${customClass}` }} variant="h3" component="h3">
      {title}
    </Typography>
  );
}

export default Title;
