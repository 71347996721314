import { ClassNames } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

function SubTitle({ SubTitle, customClass }) {
  const classes = useStyles();
  return (
    <Typography classes={{ root: `${classes.SubTitle} ${customClass}`}} variant="h6" component="h6">
      {SubTitle}
    </Typography>
  );
}

export default SubTitle;
